.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.verified{
  color:green
}

.not-verified{
  color:red
}
.revenue_button{
  height: 60px !important;
    width: 408px;
}
.search-sort{
  margin-right: 232px;
  margin-top: 66px;

}


.logo-sidebar{
  width: 82px;
  height: auto;
}
.login-logo{
  width: 38%;
  height: auto;
}

.sidebar-panel .sidebarhead ul a svg path {
  stroke:var(--nav);
}