@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Outfit", sans-serif;
}
/* Colors variables */
:root {
  --black: #000000;
  --white: #ffffff;
  --bggray: rgb(0 0 0 / 10%);
  --box-border: #dddddd;
  --forminput-border: #cacaca;
  --form-label: #6e7280;
  --primary: #8c56ff;
  --secondry: #6e44da;
  --nav: rgb(255 255 255 / 40%);
  --form-placeholder: #bfbfbf;
}
.bg-black {
  background-color: var(--black);
}
/* Fonts variables  */
:root {
  --fonts-100: "Museo Sans 100";
  --fonts-300: "Museo Sans 300";
  --fonts-500: "Museo Sans 500";
  --fonts-700: "Museo Sans 700";
  --fonts-900: "Museo Sans 900";
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 32px;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
}
a {
  text-decoration: none;
  color: #000000;
}
img {
  max-width: 100%;
}
/* Form input css  */
.form-control,
.form-select {
  padding: 20px 24px;

  border: 1px solid var(--forminput-border);
  border-radius: 12px !important;
}
.form-control::placeholder {
  color: var(--form-placeholder);
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
}
.form-control:focus,
.form-select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.25rem rgb(110 68 218 / 40%) !important;
}
.form-check-input:checked {
  background-color: var(--secondry);
  border-color: var(--secondry);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
}
.form-tabs .Form .form-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
  margin: 0 0 5px 0;
}
.form-check-label,
.Form a {
  color: var(--white);
}
.formeye .input-group-text {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0px, -50%);
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  z-index: 5;
}
.btnblack {
  border: 1px solid var(--primary);
  padding: 12px 30px;
  border-radius: 18px;
  height: auto;
  color: var(--white);
  background: var(--primary);
  box-shadow: 0px 11.4265px 39.9928px rgba(111, 126, 201, 0.25);
  border-radius: 8px;
}

.venue {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clubbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.clubbar .clubleft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.clubbar .clubleft h4,
.clubbar .clubleft h6 {
  margin: 0;
}
.clubbar .clubleft h4 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #312d2d;
  margin-right: 8px;
}
.clubbar .clubleft h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #312d2d;
  opacity: 0.4;
}
.venuebox {
  margin-bottom: 40px;
}
.venuebox h5 {
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: flex-end;
  color: #181818;
  opacity: 0.6;
  margin-bottom: 16px;
}
.venuebox p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 167.5%;
  color: #060518;
}
.venuebox .venue-media {
  width: 100%;
  height: 180px;
}
.venuebox .venue-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.btn-fill {
  padding: 19px 60px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #8c56ff;
  background: transparent;
  border: 2px solid #8c56ff;
  border-radius: 14px;
  transition: 0.3s ease;
}
.btn-fill svg {
  margin-right: 10px;
}
.btn-fill:hover {
  background: #8c56ff;
  color: #ffffff;
}
table tr th:nth-child(1) {
  padding-left: 2rem;
  border-radius: 5px 0 0 5px;
}
.verification_panel table thead tr th:nth-child(5) {
  text-align: center;
}
.verification_panel table thead tr th:nth-child(3) {
  text-align: center;
}
.verification_panel table tr td:nth-child(3) {
  text-align: center;
}
.banner_img {
  column-gap: 15px;
  position: relative;
  left: -17px;
}

.dragicon svg {
  font-size: 22px;
}
/* .dragicon {opacity: 0;transition: .3s ease;visibility: hidden;}
tr.item-container:hover  .dragicon {
    opacity: 1;
    visibility: visible;
} */

table tr td:nth-child(1) {
  padding-left: 1rem;
}
table tr th:last-child {
  border-radius: 0 5px 5px 0;
}
table thead tr {
  height: 60px;
  background: #8b56ff28;
}
table tr td {
  height: 60px;
  vertical-align: middle;
}
thead tr th {
  border: none;
}
.form-control,
.form-select {
  padding: 10px 24px;
  border: 1px solid var(--forminput-border);
  border-radius: 12px !important;
}
.form_button {
  border-radius: 12px;
  width: 100%;
  color: #fff;
  background-color: #8c56ff;
  border: none;
  height: 45px;
}
.modal-header {
  padding: 2rem 2rem 0;
  border-bottom: none;
}
.modal-content {
  border-radius: 1.3rem;
}
.modal-body {
  padding: 1.5rem 2rem 3rem 2rem;
}
.btnblack_new {
  display: flex;
  border: 1px solid var(--primary);
  padding: 12px 20px;
  border-radius: 18px;
  height: auto;
  color: var(--white);
  background: var(--primary);
  box-shadow: 0px 11.4265px 39.9928px rgb(111 126 201 / 25%);
  border-radius: 8px;
}
.btnblack_new svg {
  color: #1212126f;
}
.form-label {
  opacity: 1 !important;
}
.form-select {
  opacity: 0.5;
}
.btnblack svg {
  margin-left: 5px;
  color: var(--secondry);
}
.btnprimary {
  border: 1px solid var(--primary);
  padding: 12px 30px;
  border-radius: 18px;
  height: auto;
  color: var(--primary);
  background: var(--white);
  box-shadow: 0px 11.4265px 39.9928px rgba(111, 126, 201, 0.25);
  border-radius: 8px;
}
.btnprimary:hover {
  color: var(--white);
  background: var(--primary);
}
.btnprimary img {
  margin-right: 10px;
}
.btnprimary:hover img {
  filter: brightness(0.5);
}
.btnwhite {
  background: var(--secondry) !important;
  border: 1px solid var(--secondry) !important;
  padding: 12px 30px;
  border-radius: 18px;
  height: auto;
  color: var(--white);
}
.Form .btnwhite {
  width: 100%;
}
.btnblack:focus {
  box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 34%) !important;
}
.btnblack:hover {
  background: transparent !important;
  color: var(--primary);
  border: 1px solid var(--primary) !important;
  outline: none !important;
}
.btnblack_new:hover {
  background: transparent !important;
  color: var(--primary);
  border: 1px solid var(--primary) !important;
  outline: none !important;
}
.dashboard-boxes .boxesin .boxescontent button.btnblack {
  padding: 10px !important;
}
.playlistoption ul li button {
  min-width: 200px;
}
/* Header css  */
.mainheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}

.sidebar-panel .sidebarhead .logobar {
  text-align: center;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-panel .sidebarhead .logobar img {
  max-width: 140px;
}
.mainheader nav.navbar {
  background: var(--primary);
  padding: 0;
}
.mainheader nav.navbar .navbar-brand {
  background: var(--primary) !important;
  padding: 6px 25px !important;
}
.dashboard-wrapper .mainheader {
  display: none;
  transition: 0.4s ease-in-out;
}
.mainheader nav.navbar .navbar-brand {
  background: var(--black);
  padding: 20px 80px;
}
.main-wraper {
  background: var(--white);
  padding: 30px 0 30px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-wraper .body-wraper {
  background: var(--primary);
  border: 0.5px solid var(--box-border);
  box-shadow: 8px 9px 31px rgba(84, 114, 247, 0.09);
  border-radius: 30px;
  width: 563px;
  margin: 0 auto;
  padding: 35px;
}
.main-wraper .body-wraper .logo {
  text-align: center;
  margin-bottom: 10px;
}
.main-wraper .body-wraper .form-tabs .nav-tabs {
  margin-bottom: 28px;
  border: none;
  justify-content: center;
}
.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item {
  flex: 1 1 0;
  justify-content: space-between;
}
.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item .nav-link {
  width: 100%;
  background: var(--white);
  border: 2px solid var(--black);
  border-radius: 0;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item .nav-link.active {
  background: var(--black);
  color: var(--white);
}
.main-wraper
  .body-wraper
  .form-tabs
  .nav-tabs
  li.nav-item:first-child
  .nav-link {
  border-radius: 8px 0 0 8px;
}
.main-wraper
  .body-wraper
  .form-tabs
  .nav-tabs
  li.nav-item:last-child
  .nav-link {
  border-radius: 0px 8px 8px 0;
}

.title-bar h2 {
  font-family: var(--fonts-700);
}
.title-bar h2,
.title-bar p {
  color: var(--white);
}
.main-body .body-main .title-bar h2 {
  color: var(--primary);
}
.dashboard-wrapper {
  padding-top: 0px;
  padding-left: 311px;
  position: relative;
  min-height: 100vh;
}

.dashboard-wrapper .dashboardsidebar {
  width: 311px;
  height: 100vh;
  margin-top: 0px;
  position: fixed;
  background-color: var(--primary);
  top: 0;
  left: 0;
}
.edit {
  display: flex;
  justify-content: space-between;
}
.edit button {
  margin-inline: 5px;
  border: none;
  background: white;
}
.accordion-button:focus {
  box-shadow: none;
  outline: none;
}
.dashboard-block {
  background: var(--bggray);
}
.sidebar-panel {
  height: 100%;
  overflow: hidden;
  display: flex;
  width: 100%;
  overflow: scroll;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-panel a.logoutbtn {
  padding: 18px 25px;
  background: var(--primary);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sidebar-panel a.logoutbtn svg {
  margin-right: 10px;
}

.dashboard-wrapper .main-body {
  padding: 21px;
  margin: 0;
  max-width: 100%;
  height: 100vh;
}
.dashboard-wrapper .dashboardsidebar .sidebar-innner {
  margin-top: 1px;
  background: var(--white);
  height: 100vh;
}
.dashboard-wrapper .dashboardsidebar .sidebar-innner .accordion {
  background: var(--black);
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button,
.dashboard-wrapper .dashboardsidebar .sidebar-innner .accordion a {
  color: var(--white);
  background: var(--black);
  padding: 18px 25px 18px 25px;
  border-bottom: 1px solid rgba(80, 80, 80, 0.5);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse {
  background: var(--black);
  color: var(--white);
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  h2.accordion-header
  button.accordion-button::after {
  filter: invert(1);
  opacity: 1;
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  ul
  li:last-child
  a {
  border: none;
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body {
  padding: 10px 25px 10px 25px;
}
.dashboard-wrapper .dashboardsidebar .accordion .accordion-item {
  background-color: var(--white);
  border: none;
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  ul
  li
  a {
  display: block;
  padding: 14px 0;
  color: var(--white);
  text-decoration: none;
  border-bottom: 1px solid rgba(80, 80, 80, 0.5);
  font-size: 16px;
  line-height: 18px;
  color: var(--white);
  font-family: var(--fonts-700);
}
.dashboard-block .dashboard-wrapper .dashboardsidebar li a {
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--nav);
  text-decoration: none;
  display: block;
}
.main-body .table-bar table {
  color: var(--black);
}
.main-body .table-bar table thead {
  background: var(--bggray);
  border: none;
}
.table-bar thead tr th {
  background: var(--bggray);
  color: var(--black);
  box-shadow: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.03em;
  margin: 0;
  padding: 13px;
}
.main-body .table-bar table tbody tr {
  background: var(--white);
  border: none !important;
}
.main-body .table-bar table tbody tr td {
  background: var(--white) !important;
  border: none !important;
  box-shadow: none;
  vertical-align: middle;
}
.main-body .body-main {
  background: var(--white);
  border-radius: 0px;
}
.main-body {
  background: var(--white);
  border-radius: 0px;
}
.body-main {
  background: var(--bggray);
}
.main-body .body-main .title-bar {
  margin-bottom: 40px;
}
.table-bar table.table {
  margin-top: 30px;
}
.uploadbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.uploadbar label.form-label {
  margin: 0;
  flex: 0 0 160px;
}
.btnupload {
  max-width: 300px;
  margin: 0 0 0 auto;
}
.form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--form-label);
}
.dashboard-wrapper
  .dashboardsidebar
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  ul
  li
  a.active {
  color: var(--primary);
}
.uploadbar input.form-control {
  border: none;
}
.uploadbar input.form-control {
  border: none;
}
.btngroups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btngroups .btnblack {
  margin-left: 20px;
  max-width: 30%;
}
.forminputs-bar .form-check {
  margin-right: 20px;
}
.forminputs-bar {
  margin: 40px 0 20px 0;
}
.uploademails .input-group-text {
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  right: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--black);
  padding: 6px 30px;
  cursor: pointer;
  border-radius: 5px !important;
  margin: 0 !important;
}
.uploademails input.form-control {
  padding-right: 130px;
}
.form .btngroups {
  margin-top: 100px;
}
.form .btngroups {
  margin-top: 100px;
}
.emaillisting li {
  border-bottom: 1px solid #e9ecee;
}
.emaillisting li .del-btn {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}
.emailadded-bar {
  height: 100%;
  background: var(--white);
  border: 1px solid var(--box-border);
  border-radius: 8px;
  padding: 24px;
}
.emailadded-bar .title-bar {
  margin-bottom: 30px;
}
.sidebar-panel a.logoutbtn svg {
  margin-right: 10px;
}
.title-bar .btnblack {
  width: auto;
  padding: 12px 20px;
}
.title-bar .btnblack svg {
  margin-right: 10px;
}
.sidebar-panel .sidebarhead ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
.sidebar-panel .sidebarhead ul a {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  padding: 15px 20px 15px 20px;
  border-left: 8px solid transparent;
  cursor: pointer;
}
.sidebar-panel .sidebarhead ul a svg {
  margin-right: 15px;
}
.sidebar-panel .sidebarhead ul a.active {
  border-left-color: var(--white);
  color: var(--white);
  background: rgb(0 0 0 / 5%);
}
.table-bar table.table {
  margin-top: 30px;
}
.table-bar table.table .btngroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}
.table-bar table.table .btngroup li {
  margin-left: 10px;
}
.table-bar table.table .btngroup li button {
  border: none;
  padding: 0;
  background: transparent;
  font-size: 24px;
  color: red;
}
.table-bar table.table .btngroup li:first-child button {
  color: var(--primary);
}
.modal-vertical-centered {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.loanoffer-modal .modal-dialog {
  width: 100%;
  max-width: 500px;
}
.loanoffer-modal .modal-dialog .modal-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #181818;
  padding: 30px 40px 20px 40px;
  border: none;
}
.loanoffer-modal .modal-dialog .modal-content {
  border-radius: 20px;
}
.loanoffer-modal .modal-dialog .modal-header button.btn-close {
  border: none;
  padding: 0;
  margin: 0;
  transition: 0.5s ease;
}
.loanoffer-modal .modal-dialog .modal-content .modal-body {
  padding: 20px 40px;
}
.form-label {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #121212;
  opacity: 0.5;
}
.loanoffer-modal .input-group-text {
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}
.loanoffer-modal
  .modal-dialog
  .modal-content
  .modal-body
  form
  .loanoffer-modal {
  margin-bottom: 20px;
}
.loanoffer-modal .modal-dialog .modal-content .modal-body form {
  position: relative;
}
.dashboard-boxes .boxesin {
  background: var(--white);
  border: 2px solid var(--box-border);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 0 20px 0 0;
  flex: 0 0 30%;
}
.dashboard-boxes {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-boxes .boxesin.balancebox {
  flex: 0 0 400px;
}
.dashboard-boxes .boxesin ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-boxes .boxesin ul li {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #aaaaaa;
}
.dashboard-boxes .boxesin ul li:last-child {
  font-size: 30px;
  color: var(--secondry);
}
.dashboard-boxes .boxesin .boxescontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-boxes .boxesin .boxescontent h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 60px;
  color: var(--black);
}
.dashboard-components {
  margin-bottom: 40px;
}
.maintitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #aaaaaa;
  margin-bottom: 18px;
}
/* Image Frame  */
.imageframe {
  position: relative;
  border-radius: 8px;
}
.imageframe img {
  height: 232px;
  object-fit: cover;
  border-radius: 8px;
}
.imageframe ul.options {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}
.imageframe ul.options li {
  margin-left: 10px;
}
.imageframe ul.options li button {
  background: var(--white);
  opacity: 0.9;
  border: 1.14933px solid var(--white);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.imageframe ul.options li button.editbtn {
  color: var(--primary);
}
.imageframe ul.options li button.delbtn {
  color: red;
}
.whenempty {
  background: #ffffff;
  border: 2px dashed #8c56ff;
  border-radius: 8px;
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.whenempty button.btnupload {
  max-width: initial;
  background: transparent;
  margin: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 80px;
  color: #dddddd;
}
.whenempty button.btnupload input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.imageframe,
.whenempty {
  margin-bottom: 30px;
}
.imageframe ul.options li button.editbtn {
  position: relative;
}
.imageframe ul.options li button.editbtn input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}
.main-body .body-main {
  padding-bottom: 50px;
}
.main-body .body-main {
  padding-bottom: 50px;
}
.playlistoption h6 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #181818;
  margin-bottom: 30px;
}
.playlistoption ul li {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgb(170 170 170 / 40%);
}
.playlistoption ul li h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #aaaaaa;
  margin-bottom: 20px;
}
.playlistoption ul li:nth-child(2) {
  margin: 20px 0;
}
.playlistoption ul li button {
  min-width: 200px;
}
.playlisttbalehead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.playlisttbalehead h6 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #312d2d;
}
.btnred {
  background: #e84646;
  box-shadow: 0px 11.4265px 39.9928px rgba(111, 126, 201, 0.25);
  border-radius: 8px;
  border: 1px solid #e84646;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
}
.btnred svg {
  margin-right: 10px;
}
table {
  width: 100%;
  table-layout: inherit;
}
table thead th {
  background: rgb(140 86 255 / 10%);
  padding: 15px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: #8c56ff;
}
table tbody tr td {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #312d2d;
  padding: 20px 15px;
  border-bottom: 1px solid rgb(191 191 191 / 20%);
}
table tbody tr td img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
}

.verification_text {
  margin-bottom: 34px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #8c56ff;
}
.approve {
  background: #5cba5f !important;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: none;
}
.reject {
  background: #ffffff;
  border: 1px solid red !important;
  color: rgb(255, 0, 0);
  padding: 0.5rem 1rem;
  border-radius: 3px;
}
.crd_img {
  width: 60px;
  height: 37px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
}
.document_img {
  justify-content: center;
  display: flex;
  align-items: center;
}

.banner_img {
  justify-content: left;
  display: flex;
  align-items: center;
}

.buttons_modal {
  padding: 2rem;
  display: flex;
  justify-content: center;
}
.buttons_modal button {
  margin-inline: 7px;
}
.buttons_modal .approve {
  width: 90px;
}
.document_img a {
  font-size: 16px;
  cursor: pointer;
}
.modal_image img {
  object-fit: cover;
  width: 100%;
  max-height: 434px;
}
.dashboard-wrapper header .mainheader {
  display: none;
}
.navbar-toggler {
  margin: 1rem;
}

/* ----------------- login page -------------------- */

.login_form_wrapper,
.login_logo {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_form {
  width: 80%;
}
.login_main {
  background: white;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  justify-content: center;
}
.login_logo {
  background-image: url("/public/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  background-color: #8b56ff72;
}
.login_div {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #8c56ff80;
}

/* ----------------- three boxes user details and venue details------------------ */
.main_div_border {
  padding: 34px;
  border: 1px solid rgba(166, 166, 166, 0.532);
  border-radius: 12px;
  height: 100%;
}
.detail_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid rgba(193, 193, 193, 0.573);
}
.sortby_select {
  max-width: 200px;
}
.pagination_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}
.pagination {
  flex-wrap: wrap;
}
.detail_row h5 {
  color: #5cba5f;
}
.detail_row p {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 0.8rem !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: white;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.page-link {
  color: var(--primary);
}
.main_heading_box {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  opacity: 0.6;
  margin: 0 0 18px;
}
.left_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image_left_div {
  margin-right: 18px;
}
.checkin_row {
  padding: 31px 0;
}
.venue_detail_boxes .col-md-6 {
  margin-top: 25px;
}
.request_row {
  padding: 18px 0;
}
.light_text {
  opacity: 0.6;
  font-size: 14px !important ;
}
.top_text {
  display: flex;
}
.right_div {
  text-align: right;
}
.text_div_left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.profilepic {
  max-width: 191px;
  height: auto;
  object-fit: cover;
}
.profilepic img {
  width: 100%;
  height: 100%;
}
.color_primary {
  color: var(--primary) !important;
  opacity: 1 !important;
  display: flex;
  align-items: center;
}
.main_tabs_section {
  position: relative;
}
.sortby_select {
  width: 200px;
  height: 59px;
}

/* .posabs{
    position: absolute;
    right: 0;
    top: 0;
} */
.tabs-bg {
  background: #f5f5f5;
  display: inline-flex;
  padding: 7px;
  border-radius: 16px;
}

.tabs_div .nav-tabs {
  border-bottom: none !important;
}
.tabs_div .nav-link {
  color: var(--primary);
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 14px 42px;
}
.table-bar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: var(--primary);
  border-color: var(--secondry);
  border-radius: 8px;
}
.table_image {
  width: 60px;
  height: auto;
  object-fit: cover;
}
.pagination_box .page-link:hover {
  z-index: 2;
  color: var(--primary) !important;
  border-color: var(--secondry) !important;
}
.search_sort_div {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.posrel {
  position: relative;
}
.search_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
}
.search_bar {
  margin-right: 18px;
}
.search_bar .input-group > .form-control,
.input-group > .form-select {
  padding: 10px 10px 10px 42px;
}

.PhoneInputInput {
  border: 1px solid #dfdfdf;
  padding: 10px;
  border-radius: 12px;
}

/* Responsive css start from here  */
@media only screen and (max-width: 1199px) {
  .dashboard-wrapper .dashboardsidebar {
    display: flex;
    z-index: 10;
    transition: all 0.4s ease-in-out;
    left: -100%;
    padding-top: 92px;
  }
  .dashboard-wrapper .main-body {
    padding: 120px 40px 0;
    margin: 0;
    max-width: 100%;
    height: 100vh;
  }
  .menu-open .dashboard-wrapper .dashboardsidebar {
    left: 0;
  }
  .sidebar-panel a.logobar {
    display: none !important;
  }
  .dashboard-wrapper {
    padding-left: 0;
  }
  .dashboard-wrapper .mainheader {
    display: block;
    transition: all 0.4s ease-in-out;
  }
  .document_img {
    display: block;
  }
  .venuebox .venue-media {
    width: 100%;
    height: 180px;
    margin: 10px auto;
  }
  .posabs {
    top: 84px;
  }
  .tabs-bg {
    margin-bottom: 5.5rem !important;
  }
  .tabs_div .nav-link {
    padding: 14px 20px;
  }
}

@media only screen and (max-width: 574px) {
  .dashboard-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .dashboard-boxes .boxesin {
    flex: 0 0 100%;
    margin: 0 0 20px 0;
  }
  .dashboard-wrapper .main-body {
    padding: 120px 30px 0;
  }
  .verification_text {
    font-size: 26px;
    line-height: 32px;
  }
  .clubright {
    margin: 20px 0;
  }
  .btn-fill {
    padding: 15px 27px;
  }
}

.pagination-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.pagination-div button {
  padding: 10px 15px;
  color: #6e44da;
}

.pagination-div span {
  font-size: 18px;
}

.maintitle {
  text-align: center;
  /* Add any additional title styles */
}
.chng-pwd {
  margin: 10px 0;
}

.btn_chng_pwd {
  margin-left: 0px;
  margin-top: 19px;
}

@media screen and (min-width: 776px) {
  .payout-request .search_sort_div.search-sort {
    margin: 0;
    margin-left: auto;
    margin-top: -48px;
  }

  .payout-request .tabs-bg.nav-tabs {
    margin: 0 !important;
  }
}
