@font-face {
    font-family: 'Museo Sans 900';
    src: url('MuseoSans-900.woff2') format('woff2'),
        url('MuseoSans-900.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('MuseoSans-700.woff2') format('woff2'),
        url('MuseoSans-700.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('MuseoSans-300.woff2') format('woff2'),
        url('MuseoSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('MuseoSans-100.woff2') format('woff2'),
        url('MuseoSans-100.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('MuseoSans-100.woff2') format('woff2'),
        url('MuseoSans-100.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('MuseoSans-500.woff2') format('woff2'),
        url('MuseoSans-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('MuseoSans-300.woff2') format('woff2'),
        url('MuseoSans-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

